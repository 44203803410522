import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  device = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["token", "user", "device"],
      storage: window.localStorage,
    });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  clear() {
    this.token = "";
    this.user = null;
  }

  setDeviceCode = (device) => {
    this.device = device;
  };

  updateToken(token) {
    this.token = token || this.token;
  }

  updateUser(user) {
    this.user = user;
    if (user?.first_name) {
      this.user.full_name = `${user?.first_name} ${user?.last_name}`;
    } else {
      this.user.full_name = user.user_type;
    }
  }
}
