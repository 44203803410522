import React, { useState } from "react";
import {
  Button,
  Card,
  formatDate,
  get,
  GridView,
  Popconfirm,
  Table,
} from "remix-dls";
import { observer } from "mobx-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CategoryApi } from "states/api";
import CreateUpdateModal from "containers/Category/CreateUpdateModal";
import { message } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const LotteryPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page");

  const { data, isLoading } = useQuery(
    ["category", "list", currentPage],
    () =>
      CategoryApi.getList({
        params: {
          page: currentPage || 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate: onDelete } = useMutation(
    (id) => {
      return CategoryApi.delete({ id });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["category", "list"]);
        message.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        message.error(errorMessage);
      },
    }
  );

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [detailData, setDetailData] = useState();

  const onOpenUpdateModal = (item) => {
    setOpenModalCreate(true);
    setDetailData(item);
  };

  const onCloseUpdateModal = (item) => {
    setOpenModalCreate(false);
    setDetailData(null);
  };

  return (
    <div className="remix-page">
      <div className="main-content-inner">
        <div className="page-container">
          <div className="remix-sub-header">
            <h2 className="remix-page-title">Danh mục</h2>
            <div className="remix-page-control">
              <Button
                className="btn-control btn-success"
                onClick={() => setOpenModalCreate(true)}
              >
                Thêm danh mục
              </Button>
            </div>
          </div>

          <div className="page-overview">
            {/* <ContactFilter
              onGetList={this.props.onGetList}
              typeDefault={this.state.filters.type}
            /> */}
          </div>
          <Card>
            <GridView
              column={[
                {
                  key: "id",
                  title: "Id",
                  dataIndex: "id",
                  width: "10%",
                },
                {
                  key: "name",
                  title: "Tiêu đề",
                  dataIndex: "name",
                  width: "20%",
                },
                {
                  key: "type",
                  title: "Loại",
                  dataIndex: "type",
                  width: "20%",
                },
                {
                  key: "created_at",
                  title: "Ngày tạo",
                  dataIndex: "created_at",
                  width: "20%",
                  render: (value) => (
                    <span className="text">{value && formatDate(value)}</span>
                  ),
                },
                {
                  key: "actions",
                  dataIndex: "id",
                  align: "right",
                  width: "25%",
                  render: (id, item) => {
                    return (
                      <div className="btn-group">
                        <Button
                          className="btn-sm btn-default"
                          onClick={() => navigate(`/category/list-child/${id}`)}
                        >
                          Danh mục con
                        </Button>
                        <Button
                          className="btn-sm btn-info"
                          onClick={() => onOpenUpdateModal(item)}
                        >
                          Sửa
                        </Button>

                        <Popconfirm
                          placement="top"
                          title="Bạn có chắc chắn muốn xoá dữ liệu này không?"
                          onConfirm={() => onDelete(id)}
                          okText="Xác nhận"
                          cancelText="Huỷ"
                        >
                          <Button className="btn-sm btn-danger">Xóa</Button>
                        </Popconfirm>
                      </div>
                    );
                  },
                },
              ]}
              data={data?.data || []}
              pagination={{
                current: parseInt(get(data, "current_page"), 10) || 1,
                pageSize: parseInt(get(data, "per_page"), 10) || 10,
                total: get(data, "total"),
                onChange: (page) => {
                  navigate(`/category/list?page=${page}`);
                },
              }}
              loading={isLoading}
            />
          </Card>
        </div>
        {openModalCreate && (
          <CreateUpdateModal
            visible={openModalCreate}
            onClose={onCloseUpdateModal}
            onRefreshData={() => {
              queryClient.invalidateQueries(["category", "list"]);
            }}
            detail={detailData}
          />
        )}
      </div>
    </div>
  );
});

export default LotteryPage;
