import React from "react";
import { Form, Input, Select, TextArea } from "remix-dls";

export default function FormData() {
  return (
    <>
      <Form.Item
        label="Tiêu đề"
        name="name"
        rules={[
          {
            required: true,
            message: "Vui lòng nhập tiêu đề",
          },
        ]}
      >
        <Input placeholder="Nhập tiêu đề" />
      </Form.Item>
      <Form.Item label="Mã danh mục" name="short_code">
        <Input placeholder="Nhập Mã danh mục" />
      </Form.Item>
      <Form.Item label="Slug" name="slug">
        <Input placeholder="Nhập Slug" />
      </Form.Item>
      <Form.Item label="Loại danh mục" name="status">
        <Select placeholder="Chọn trạng thái">
          <Select.Option value="inactive">Bình thường</Select.Option>
          <Select.Option value="active">Kích hoạt</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Mô tả" name="description">
        <TextArea autoSize={{ minRows: 3 }} placeholder="Nhập mô tả" />
      </Form.Item>
    </>
  );
}
