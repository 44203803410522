import React, { useEffect, useMemo, useState } from "react";
import {
  AvatarHeader,
  Button,
  ButtonNotification,
  Header,
  Menu,
  Input,
  updateQueryParams,
  formatDate,
} from "remix-dls";
import { useNavigate } from "react-router-dom";
// redux
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { message } from "antd";
import { AuthApi } from "states/api";
import { useMutation } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";

const HeaderLayout = observer(({ toggleMenu, toggleCollapsed }) => {
  const navigate = useNavigate();
  const { authStore } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      message.success(res?.data?.msg);
      authStore.clear();
    },
    onError: (error) => {
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      message.error(errorMessage);
    },
  });

  const handleOpenProfile = (item) => {
    navigate(`/profile`);
  };

  const menuUser = (
    <Menu>
      <Menu.Item key="1" onClick={handleOpenProfile}>
        Thông tin tài khoản
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">Cài đặt</Menu.Item>
      <Menu.Item key="3" onClick={onLogout}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <Header>
      <div className="remix-header-left ">
        <div className="remix-logo-title hide-mobile">
          <h2>Quản trị website</h2>
        </div>
        <div className="remix-toggle-menu">
          <Button
            className="btn-menu-header"
            onClick={() => toggleCollapsed(!toggleMenu)}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </div>
      </div>
      <div className="remix-header-right">
        <AvatarHeader title={authStore.user?.full_name} menu={menuUser} />
      </div>
    </Header>
  );
});

export default HeaderLayout;
