import React, { useEffect, useState } from "react";
import {
  Button,
  InputNumber,
  Form,
  Select,
  Modal,
  Upload,
  Input,
  TextArea,
  message,
  get,
} from "remix-dls";

import { useMutation } from "@tanstack/react-query";
import { OptionApi } from "states/api/options";
import UploadCustom from "_common/component/UploadCustom";
import { listPageType, listPositions } from "_common/constants/ads";
import { Spin } from "antd";

export default function CreateUpdateModal({
  visible,
  onClose,
  detail,
  onRefreshData,
}) {
  const [form] = Form.useForm();

  const [images, setListImages] = useState([]);

  const imageData = get(detail, "image_url", "");
  useEffect(() => {
    if (!imageData) return;
    setListImages([
      {
        url: imageData,
      },
    ]);
  }, [imageData]);

  const { mutate, isLoading } = useMutation(
    (variables) => {
      const newValues = {
        ...variables,
        type: "banner",
      };
      if (detail?.id) {
        return OptionApi.update({ id: detail?.id, params: newValues });
      }

      return OptionApi.create({ params: newValues });
    },
    {
      onSuccess: (res) => {
        onClose();
        if (typeof onRefreshData === "function") {
          onRefreshData();
        }
        message.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        message.error(errorMessage);
      },
    }
  );

  const handleReceiveImg = (images) => {
    form.setFieldsValue({
      images: get(images[0], "url"),
    });
  };

  const id = get(detail, "id");

  useEffect(() => {
    if (visible) return;
    form.resetFields();
  }, [form, visible]);
  useEffect(() => {
    if (!detail) return;
    form.setFieldsValue(detail);
  }, [detail, form]);

  const handleCancel = () => {
    onClose(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={id ? "Chi tiết quảng cáo" : "Tạo mới quảng cáo"}
      onCancel={() => onClose(false)}
      maskClosable={false}
      footer={[
        <div key="control">
          <Button className="btn-default" onClick={() => handleCancel()}>
            Quay lại
          </Button>
          <Button
            disabled={isLoading}
            className="btn-success"
            onClick={() => form.submit()}
          >
            Lưu
          </Button>
        </div>,
      ]}
    >
      <Spin tip="loading" spinning={isLoading}>
        <Form
          layout="vertical"
          className="form-cms"
          style={{ marginTop: 0 }}
          onFinish={mutate}
          initialValues={{
            amount: 0,
          }}
          form={form}
        >
          <div className="section">
            <Form.Item label="Tiêu đề" name="value">
              <Input placeholder="Tên tiêu đề" />
            </Form.Item>
            <Form.Item
              label="Thứ tự quảng cáo"
              name="order"
              help="Nhập số thứ tự không trùng lặp trong cùng 1 trang hoặc 1 vị trí"
            >
              <InputNumber min={0} placeholder="Thứ tự" />
            </Form.Item>
            <Form.Item label="Liên kết (URL)" name="link">
              <Input placeholder="Liên kết (URL)" />
            </Form.Item>
            <Form.Item
              label="Vị trí quảng cáo"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn một trang",
                },
              ]}
            >
              <Select placeholder="Vị trí quảng cáo">
                {Object.keys(listPositions).map((item, index) => (
                  <Select.Option key={`item-${index.toString()}`} value={item}>
                    {listPositions[item]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tải file"
              name="images"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn một file",
                },
              ]}
            >
              <UploadCustom
                fileList={images}
                onReceiveImages={handleReceiveImg}
                multiple={false}
                maxImages={1}
                folder="quảng cáo"
              />
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}
