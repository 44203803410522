import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const LotteryEndpoint = `${endpoint}/manager/lottery`;

export const LotteryApi = {
  getList: async ({ params }) =>
    await api
      .get(`${LotteryEndpoint}/list`, params)
      .then((result) => result.data?.data),
  syncData: async (req) =>
    await api
      .post(`${LotteryEndpoint}/sync-data`, req?.params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${LotteryEndpoint}/detail/${id}`)
      .then((result) => result.data),
  create: async ({ params }) =>
    await api
      .post(`${LotteryEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${LotteryEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${LotteryEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
