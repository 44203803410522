import React from "react";

const DashboardPage = () => {
  return (
    <div className="main-content-inner">
      <h2>Chào mừng bạn đến với hệ thống</h2>
    </div>
  );
};

export default DashboardPage;
