/* eslint-disable no-use-before-define */
import React from "react";
import { Sider, Menu, SubMenu } from "remix-dls";
import { NavLink } from "react-router-dom";

import data from "./data";

export default function SlideBar({ toggleMenu, toggleCollapsed }) {
  const renderSubMenu = (item) => (
    <SubMenu key={item.key} title={item.text} icon={item.icon}>
      {item.subItem.map(renderMenu)}
    </SubMenu>
  );

  const renderMenu = (item) => {
    if (item.subItem) return renderSubMenu(item);
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        <NavLink to={item.to}>{item.text}</NavLink>
      </Menu.Item>
    );
  };

  return (
    <>
      <Sider
        collapsedWidth={0}
        collapsed={toggleMenu}
        onCollapse={toggleCollapsed}
      >
        <Menu mode="inline">{data.map(renderMenu)}</Menu>
      </Sider>
    </>
  );
}
