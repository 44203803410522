import React, { useState } from "react";
import {
  SettingOutlined,
  PieChartOutlined,
  UserOutlined,
  DatabaseOutlined,
  DollarOutlined,
} from "@ant-design/icons";

import SiteUrl from "_common/constants/url";

const data = [
  {
    key: "dashboard",
    to: "/dashboard",
    text: "Tổng quan",
    icon: <PieChartOutlined />,
    role: true,
  },
  {
    key: "lottery",
    to: "/lottery",
    text: "Quản lý Xổ Xố",
    icon: <DatabaseOutlined />,
    role: "admin",
    subItem: [
      {
        key: "/lottery/list",
        to: "/lottery/list",
        text: "Danh Sách",
        role: "admin",
      },
      {
        key: "/category/list",
        to: "/category/list",
        text: "Danh mục",
        role: "admin",
      },
    ],
  },
  {
    key: "ads",
    to: "/ads",
    text: "Quản lý Quảng cáo",
    icon: <DollarOutlined />,
    role: "admin",
    subItem: [
      {
        key: "/ads/list",
        to: "/ads/list",
        text: "Danh Sách",
        role: "admin",
      },
    ],
  },
  {
    key: "user",
    to: "/user",
    text: "User",
    icon: <UserOutlined />,
    role: "admin",
    subItem: [
      {
        key: "/user/list",
        to: "/user/list",
        text: "Danh Sách",
        role: "admin",
      },
      // {
      //   key: "/user/role",
      //   to: "/user/role",
      //   text: "Danh Sách Phân Quyền",
      //   role: "admin",
      // },
    ],
  },
  {
    key: "setting",
    to: "/setting",
    text: "Thiết lập",
    role: "admin",
    icon: <SettingOutlined />,
    subItem: [
      {
        key: "/setting/config",
        to: "/setting/config",
        text: "Cáu hình chung",
        role: "admin",
      },
    ],
  },
];

export default data;
