import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { Button, Spin, Form, Upload, Input, Collapse, message } from "antd";

import { get, omit } from "lodash";

import getFilterParams from "_common/utils/getFilterParams";
import TextArea from "antd/lib/input/TextArea";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { OptionApi } from "states/api/options";
import UploadCustom from "_common/component/UploadCustom";

export default function GeneralPage({ parent_id, onSubmit, type }) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [images, setListImages] = useState([]);

  const { data, isLoading } = useQuery(
    ["config", "list"],
    () =>
      OptionApi.getList({
        params: {
          type: "general",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const options = data?.data || [];

  const { mutate, isLoading: submitLoading } = useMutation(
    (variables) => {
      const newValues = [];
      Object.keys(variables).map((item, key) => {
        const formValue = {
          name: item,
          value: variables[item],
          type: "general",
        };

        newValues.push(formValue);
        return null;
      });

      OptionApi.create({
        params: {
          data: newValues,
          list: true,
        },
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["config", "list"]);
        message.success(res?.data?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        message.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (!options) return;
    const newsValue = {};

    options.forEach((item, index) => {
      newsValue[item.name] = item?.value;
      if (item?.images) {
        newsValue[item.name] = item?.image_url;
      }
    });

    if (newsValue?.logo) {
      setListImages([
        {
          url: newsValue?.logo,
        },
      ]);
    }

    form.setFieldsValue(newsValue);
  }, [options]);

  const handleReceiveImg = (images) => {
    form.setFieldsValue({
      logo: get(images[0], "url"),
    });
  };

  return (
    <Spin tip="Loading ..." spinning={submitLoading || isLoading}>
      <Form
        layout="vertical"
        className="form-cms"
        style={{ marginTop: 0 }}
        onFinish={mutate}
        initialValues={{}}
        form={form}
      >
        <Form.Item label="Số điện thoại" name="phone">
          <Input placeholder="Số điện thoại" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item label="Link chat live" name="link_chat_live">
          <Input placeholder="Link chat live" />
        </Form.Item>
        <Form.Item label="Link FB" name="link_facebook">
          <Input placeholder="Link FB" />
        </Form.Item>
        <Form.Item label="Link Telegram" name="link_telegram">
          <Input placeholder="Link Telegram" />
        </Form.Item>
        <Form.Item label="Link Zalo" name="link_zalo">
          <Input placeholder="Link Zalo" />
        </Form.Item>
        <Form.Item label="Logo" name="logo">
          <UploadCustom
            fileList={images}
            onReceiveImages={handleReceiveImg}
            multiple={false}
            maxImages={1}
            folder="logo"
          />
        </Form.Item>
        <Form.Item>
          <Button type="submit" onClick={() => form.submit()}>
            Lưu cấu hình
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
