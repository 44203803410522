import React from "react";
import { Card } from "remix-dls";
import { observer } from "mobx-react";
import GeneralPage from "containers/Config/GeneralPage";

const ConfigPage = observer(() => {
  return (
    <div className="remix-page">
      <div className="main-content-inner">
        <div className="page-container">
          <div className="remix-sub-header">
            <h2 className="remix-page-title">Cấu hình chung</h2>
          </div>
          <Card>
            <GeneralPage />
          </Card>
        </div>
      </div>
    </div>
  );
});

export default ConfigPage;
