import React from "react";
import { Card } from "remix-dls";
import { observer } from "mobx-react";
import { useQuery } from "@tanstack/react-query";
import { ContactApi } from "states/api";

// import ContactFilter from "containers/Contact/Filter/ContactFilter";
// import ContactCreateModal from "containers/Contact/ContactCreateModal";
// import UserChangePassword from "containers/Contact/UserChangePassword";

const listStatus = {
  inactive: "Chưa kích hoạt",
  active: "Đã kích hoạt",
};

const UserPage = observer(() => {
  const { data, isLoading } = useQuery(
    ["contact", "list"],
    ContactApi.getList,
    {
      staleTime: 300000,
    }
  );

  // if (!this.props.profile?.isAdmin) {
  //   return (
  //     <Result
  //       status="403"
  //       title="không có quyền truy cập"
  //       subTitle="Xin lỗi, Bạn không có quyền truy cập vào trang này!"
  //     />
  //   );
  // }

  console.log("data", data);

  return (
    <div className="main-content">
      <div className="main-content-inner">
        <div className="page-container">
          <div className="page-title">
            <h1>Danh sách người dùng</h1>
            {/* {this.props.profile?.isAdmin === true && (
              <div className="control">
                <Button
                  className="btn-control btn-approve"
                  onClick={() => this._handleOpenModalCreate(true)}
                >
                  Thêm
                </Button>
              </div>
            )} */}
          </div>

          <div className="page-overview">
            {/* <ContactFilter
              onGetList={this.props.onGetList}
              typeDefault={this.state.filters.type}
            /> */}
          </div>
          <Card>
            {/* <Table
              rowKey={(i) => i.id}
              columns={[
                {
                  key: "user_id",
                  title: "Id",
                  dataIndex: "id",
                },
                {
                  key: "username",
                  title: "Username",
                  dataIndex: "username",
                  render: (username, rows) => {
                    return <p>{rows?.username}</p>;
                  },
                },
                {
                  key: "phone",
                  title: "SDT",
                  dataIndex: "contact_number",
                },
                {
                  key: "first_name",
                  title: "Tên hiện thị",
                  dataIndex: "first_name",
                },
                {
                  key: "amount",
                  title: "Số dư",
                  dataIndex: "amount",
                  width: "15%",
                  render: (final_total, row) => {
                    const userCoins = get(row, "user_coin", []) || [];

                    return (
                      <>
                        {userCoins.map((item, key) => {
                          return (
                            <p key={`item-${key.toString()}`}>
                              {`${formatNumber(item?.amount)} ${
                                item?.token?.symbol
                              }`.toLocaleUpperCase()}
                            </p>
                          );
                        })}
                      </>
                    );
                  },
                },
                {
                  key: "status",
                  title: "Trạng thái",
                  dataIndex: "status",
                  render: (status) => (
                    <Tag color={status === "active" ? "green" : "red"}>
                      {listStatus[status]}
                    </Tag>
                  ),
                },
                {
                  key: "actions",
                  dataIndex: "id",
                  render: (id) => {
                    return (
                      <div className="btn-group">
                        <Button
                          className="btn-sm btn-info"
                          onClick={() => this._handleViewUser(id)}
                        >
                          Sửa
                        </Button>
                        {this.props.profile?.isAdmin && (
                          <>
                            <Button
                              className="btn-sm btn-reject"
                              onClick={() => this._handleDeleteRow(id)}
                            >
                              Xóa
                            </Button>
                            <Button
                              className="btn-sm btn-success"
                              onClick={() => this._handleChangePassword(id)}
                            >
                              Đổi mật khẩu
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              dataSource={listItems}
              onChange={this._handleTableChange}
              onRow={(record, rowIndex) => {
                // return {
                //   onDoubleClick: () => this._handleViewUser(record.id),
                // };
              }}
              pagination={{
                current: parseInt(get(metadata, "current_page"), 10) || 1,
                pageSize: parseInt(get(metadata, "per_page"), 10) || 10,
                total: get(metadata, "total"),
              }}
              loading={loading}
              scroll={{ x: 375 }}
            /> */}
          </Card>
        </div>
        {/* <ContactCreateModal
          visible={this.state.showOpenModal}
          onClose={this._handleOpenModalCreate}
          detail={this.state.detail}
        />
        <UserChangePassword
          visible={this.state.showOpenModalPassword}
          onClose={this._handleOpenModalPassword}
          detail={this.state.detail}
        /> */}
      </div>
    </div>
  );
});

export default UserPage;
