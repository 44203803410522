import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

// page
import Dashboard from "pages/Dashboard";
import UserPage from "pages/User/UserPage";
import CategoryPage from "pages/Category/CategoryPage";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import CategoryChildPage from "pages/Category/CategoryChildPage";
import LotteryPage from "pages/Lottery/LotteryPage";
import AdsPage from "pages/Ads/AdsPage";
import ConfigPage from "pages/Config/ConfigPage";

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user">
          <Route path="list" element={<UserPage />} />
        </Route>
        <Route path="lottery">
          <Route path="list" element={<LotteryPage />} />
        </Route>
        <Route path="category">
          <Route path="list" element={<CategoryPage />} />
          <Route path="list-child/:id" element={<CategoryChildPage />} />
        </Route>
        <Route path="ads">
          <Route path="list" element={<AdsPage />} />
        </Route>
        <Route path="setting">
          <Route path="config" element={<ConfigPage />} />
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
};
