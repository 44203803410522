import React from "react";

import "antd/dist/antd.css";
import "draft-js/dist/Draft.css";
import "remix-dls/lib/css/main.css";
import "_common/styles/styles.scss";

import Root from "./Root";

function App() {
  return <Root />;
}

export default App;
